/* Estilos específicos para o componente Footer usando CSS Modules */
.footer {
    background-color: var(--color-secondary);
    color: #ffffff;
    padding: 20px;
    text-align: center;
  }
  
  .footer p {
    margin: 0;
    font-size: var(--font-size-base);
  }
  
  .socialLinks {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .socialLink {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .socialLink:hover {
    color: var(--color-accent);
  }
  
  /* Layout Responsivo para Footer */
  @media (max-width: 768px) {
    .footer {
      padding: 15px;
    }
  
    .socialLinks {
      flex-direction: column;
      gap: 10px;
    }
  }
  