/* Estilos específicos para o componente Booking usando CSS Modules */
.bookingContainer {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--color-background);
    border-radius: 8px;
  }
  
  .bookingTitle {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    margin-bottom: 20px;
    color: var(--color-secondary);
    text-align: center;
  }
  
  .bookingForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .bookingLabel {
    font-weight: var(--font-weight-bold);
    color: var(--color-text);
  }
  
  .bookingInput,
  .bookingSelect {
    padding: 10px;
    border: 1px solid var(--color-accent);
    border-radius: 5px;
    font-size: var(--font-size-base);
  }
  
  .bookingButton {
    padding: 10px 20px;
    background-color: var(--color-secondary);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    align-self: center;
  }
  
  .bookingButton:hover {
    background-color: var(--color-accent);
    color: var(--color-secondary);
  }
  