/* Estilos específicos para o conteúdo principal usando CSS Modules */
.mainContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--color-background);
  }
  
  .mainTitle {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    margin-bottom: 20px;
    text-align: center;
    color: var(--color-secondary);
  }
  
  .mainText {
    font-size: var(--font-size-base);
    line-height: 1.6;
    color: var(--color-text);
    margin-bottom: 20px;
  }
  
  .mainButton {
    padding: 10px 20px;
    background-color: var(--color-secondary);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: var(--font-size-base);
    transition: background-color 0.3s, color 0.3s;
  }
  
  .mainButton:hover {
    background-color: var(--color-accent);
    color: var(--color-secondary);
  }
  
  /* Layout Responsivo para Conteúdo Principal */
  @media (max-width: 768px) {
    .mainContainer {
      padding: 15px;
    }
  
    .mainTitle {
      font-size: 1.8rem;
    }
  
    .mainButton {
      width: 100%;
      padding: 15px;
    }
  }
  