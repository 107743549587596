/* Estilos específicos para o componente VideoSession usando CSS Modules */
.videoSessionContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--color-background);
    border-radius: 8px;
    text-align: center;
  }
  
  .videoSessionTitle {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    margin-bottom: 20px;
    color: var(--color-secondary);
  }
  
  .videoElement {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
    background-color: #000;
  }
  
  .controlsContainer {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .controlButton {
    padding: 10px 20px;
    background-color: var(--color-secondary);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .controlButton:hover {
    background-color: var(--color-accent);
    color: var(--color-secondary);
  }
  