/* Paleta de cores da plataforma Equilibria */
:root {
    --color-primary: #E6E6FA; /* Lavanda */
    --color-secondary: #87CEEB; /* Azul Suave */
    --color-accent: #D3D3D3; /* Cinza Claro */
    --color-text: #333333; /* Cinza escuro para o texto principal */
    --color-background: #F5F5F5; /* Fundo claro para manter um visual sereno */
  
    --font-family-primary: 'Roboto', sans-serif;
    --font-size-base: 16px;
    --font-size-large: 24px;
    --font-weight-bold: 700;
  }
  
  /* Estilos globais */
  body {
    font-family: var(--font-family-primary);
    font-size: var(--font-size-base);
    background-color: var(--color-background);
    color: var(--color-text);
    margin: 0;
    padding: 0;
    line-height: 1.6;
  }
  
  /* Cabeçalho */
  header {
    background-color: var(--color-secondary);
    padding: 20px 0;
    color: #ffffff;
  }
  
  nav ul {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
  }
  
  nav ul li {
    display: inline;
  }
  
  nav a {
    color: #ffffff;
    text-decoration: none;
    font-weight: var(--font-weight-bold);
    transition: color 0.3s;
  }
  
  nav a:hover {
    color: var(--color-accent);
  }
  
  /* Botões de autenticação */
  .auth-buttons {
    margin-left: auto;
    display: flex;
    gap: 15px;
  }
  
  .auth-buttons button {
    background-color: transparent;
    border: 2px solid #ffffff;
    color: #ffffff;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    font-size: var(--font-size-base);
  }
  
  .auth-buttons button:hover {
    background-color: #ffffff;
    color: var(--color-secondary);
  }
  
  /* Layout Responsivo */
  @media (max-width: 768px) {
    nav ul {
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }
  
    .auth-buttons {
      margin-top: 10px;
    }
  }
  