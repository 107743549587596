/* Estilos específicos para o componente ContactForm usando CSS Modules */
.contactFormContainer {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--color-background);
    border-radius: 8px;
  }
  
  .contactFormTitle {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    margin-bottom: 20px;
    color: var(--color-secondary);
    text-align: center;
  }
  
  .contactForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .contactFormLabel {
    font-weight: var(--font-weight-bold);
    color: var(--color-text);
  }
  
  .contactFormInput,
  .contactFormTextarea {
    padding: 10px;
    border: 1px solid var(--color-accent);
    border-radius: 5px;
    font-size: var(--font-size-base);
  }
  
  .contactFormTextarea {
    height: 100px;
    resize: vertical;
  }
  
  .contactFormButton {
    padding: 10px 20px;
    background-color: var(--color-secondary);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    align-self: center;
  }
  
  .contactFormButton:hover {
    background-color: var(--color-accent);
    color: var(--color-secondary);
  }
  