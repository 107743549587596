/* Estilos específicos para o componente Header usando CSS Modules */
.header {
    background-color: var(--color-secondary);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .nav {
    flex: 1;
  }
  
  .nav ul {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
  }
  
  .nav ul li {
    display: inline;
  }
  
  .nav a {
    color: #ffffff;
    text-decoration: none;
    font-weight: var(--font-weight-bold);
    transition: color 0.3s;
  }
  
  .nav a:hover {
    color: var(--color-accent);
  }
  
  .authButtons {
    display: flex;
    gap: 15px;
  }
  
  .authButton {
    background-color: transparent;
    border: 2px solid #ffffff;
    color: #ffffff;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    font-size: var(--font-size-base);
  }
  
  .authButton:hover {
    background-color: #ffffff;
    color: var(--color-secondary);
  }
  
  /* Layout Responsivo para Header */
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      align-items: center;
    }
  
    .nav ul {
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }
  
    .authButtons {
      margin-top: 10px;
    }
  }