/* Estilos específicos para o componente UserProfile usando CSS Modules */
.profileContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--color-background);
    border-radius: 8px;
  }
  
  .profileTitle {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    margin-bottom: 20px;
    color: var(--color-secondary);
    text-align: center;
  }
  
  .profileDetails {
    margin-bottom: 20px;
  }
  
  .profileLabel {
    font-weight: var(--font-weight-bold);
    color: var(--color-text);
    margin-bottom: 5px;
    display: block;
  }
  
  .profileText {
    font-size: var(--font-size-base);
    color: var(--color-text);
    margin-bottom: 15px;
  }
  
  .profileSessions {
    list-style: none;
    padding: 0;
  }
  
  .sessionItem {
    background-color: var(--color-accent);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .profileButton {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    background-color: var(--color-secondary);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .profileButton:hover {
    background-color: var(--color-accent);
    color: var(--color-secondary);
  }
  