/* Estilos específicos para o componente Testimonials usando CSS Modules */
.testimonialsContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--color-background);
    border-radius: 8px;
  }
  
  .testimonialsTitle {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    margin-bottom: 20px;
    color: var(--color-secondary);
    text-align: center;
  }
  
  .testimonialsList {
    list-style: none;
    padding: 0;
  }
  
  .testimonialItem {
    background-color: var(--color-accent);
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  .testimonialContent {
    font-size: var(--font-size-base);
    color: var(--color-text);
    margin-bottom: 10px;
  }
  
  .testimonialAuthor {
    font-weight: var(--font-weight-bold);
    color: var(--color-text);
    text-align: right;
  }
  